define("discourse/plugins/discourse-ai/discourse/components/modal/share-full-topic-modal", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/utilities", "discourse-common/helpers/i18n", "discourse-common/lib/get-url", "discourse-i18n", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _object, _service, _template, _dButton, _dModal, _ajax, _ajaxError, _utilities, _i18n, _getUrl, _discourseI18n, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _ShareModal;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ShareModal = _exports.default = (_class = (_ShareModal = class ShareModal extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "toasts", _descriptor, this);
      _initializerDefineProperty(this, "shareKey", _descriptor2, this);
      this.shareKey = this.args.model.share_key;
    }
    get htmlContext() {
      let context1 = [];
      this.args.model.context.forEach(post1 => {
        context1.push(`<p><b>${post1.username}:</b></p>`);
        context1.push(post1.cooked);
      });
      return (0, _template.htmlSafe)(context1.join("\n"));
    }
    async generateShareURL() {
      try {
        const response1 = await (0, _ajax.ajax)("/discourse-ai/ai-bot/shared-ai-conversations", {
          type: "POST",
          data: {
            topic_id: this.args.model.topic_id
          }
        });
        const url1 = (0, _getUrl.getAbsoluteURL)(`/discourse-ai/ai-bot/shared-ai-conversations/${response1.share_key}`);
        this.shareKey = response1.share_key;
        return new Blob([url1], {
          type: "text/plain"
        });
      } catch (e1) {
        (0, _ajaxError.popupAjaxError)(e1);
        return;
      }
    }
    get primaryLabel() {
      return this.shareKey ? "discourse_ai.ai_bot.share_full_topic_modal.update" : "discourse_ai.ai_bot.share_full_topic_modal.share";
    }
    async deleteLink() {
      try {
        await (0, _ajax.ajax)(`/discourse-ai/ai-bot/shared-ai-conversations/${this.shareKey}.json`, {
          type: "DELETE"
        });
        this.shareKey = null;
      } catch (e1) {
        (0, _ajaxError.popupAjaxError)(e1);
      }
    }
    async share() {
      await (0, _utilities.clipboardCopyAsync)(this.generateShareURL.bind(this));
      this.toasts.success({
        duration: 3000,
        data: {
          message: _discourseI18n.default.t("discourse_ai.ai_bot.conversation_shared")
        }
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DModal
        class="ai-share-full-topic-modal"
        @title={{i18n "discourse_ai.ai_bot.share_full_topic_modal.title"}}
        @closeModal={{@closeModal}}
      >
        <:body>
          <div class="ai-share-full-topic-modal__body">
            {{this.htmlContext}}
          </div>
        </:body>
  
        <:footer>
          <DButton
            class="btn-primary confirm"
            @icon="copy"
            @action={{this.share}}
            @label={{this.primaryLabel}}
          />
          {{#if this.shareKey}}
            <DButton
              class="btn-danger"
              @icon="far-trash-alt"
              @action={{this.deleteLink}}
              @label="discourse_ai.ai_bot.share_full_topic_modal.delete"
            />
          {{/if}}
        </:footer>
      </DModal>
    
  */
  {
    "id": "In8VW+gA",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"ai-share-full-topic-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"discourse_ai.ai_bot.share_full_topic_modal.title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"ai-share-full-topic-modal__body\"],[12],[1,\"\\n          \"],[1,[30,0,[\"htmlContext\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn-primary confirm\"]],[[\"@icon\",\"@action\",\"@label\"],[\"copy\",[30,0,[\"share\"]],[30,0,[\"primaryLabel\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"shareKey\"]],[[[1,\"          \"],[8,[32,2],[[24,0,\"btn-danger\"]],[[\"@icon\",\"@action\",\"@label\"],[\"far-trash-alt\",[30,0,[\"deleteLink\"]],\"discourse_ai.ai_bot.share_full_topic_modal.delete\"]],null],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/modal/share-full-topic-modal.js",
    "scope": () => [_dModal.default, _i18n.default, _dButton.default],
    "isStrictMode": true
  }), _ShareModal), _ShareModal), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "toasts", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "shareKey", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "deleteLink", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteLink"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "share", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "share"), _class.prototype)), _class);
});
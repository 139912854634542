define("discourse/plugins/discourse-ai/discourse/connectors/search-menu-initial-options/ai-quick-semantic-search", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/search-menu/results/assistant-item", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/search", "discourse-common/helpers/i18n", "@ember/template-factory", "@ember/component"], function (_exports, _component, _object, _service, _assistantItem, _ajax, _ajaxError, _search, _i18n, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _AiQuickSemanticSearch;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AiQuickSemanticSearch = _exports.default = (_class = (_AiQuickSemanticSearch = class AiQuickSemanticSearch extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "search", _descriptor, this);
      _initializerDefineProperty(this, "quickSearch", _descriptor2, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
    }
    static shouldRender(_args1, _ref) {
      let {
        siteSettings: siteSettings1
      } = _ref;
      return siteSettings1.ai_embeddings_semantic_quick_search_enabled;
    }
    async searchTermChanged() {
      if (!this.search.activeGlobalSearchTerm) {
        this.search.noResults = false;
        this.search.results = {};
        this.quickSearch.loading = false;
        this.quickSearch.invalidTerm = false;
      } else if (!(0, _search.isValidSearchTerm)(this.search.activeGlobalSearchTerm, this.siteSettings)) {
        this.search.noResults = true;
        this.search.results = {};
        this.quickSearch.loading = false;
        this.quickSearch.invalidTerm = true;
        return;
      } else {
        await this.performSearch();
      }
    }
    async performSearch() {
      this.quickSearch.loading = true;
      this.quickSearch.invalidTerm = false;
      try {
        const results1 = await (0, _ajax.ajax)(`/discourse-ai/embeddings/quick-search`, {
          data: {
            q: this.search.activeGlobalSearchTerm
          }
        });
        const searchResults1 = await (0, _search.translateResults)(results1);
        if (searchResults1) {
          this.search.noResults = results1.resultTypes.length === 0;
          this.search.results = searchResults1;
        }
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      } finally {
        this.quickSearch.loading = false;
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{yield}}
  
      {{#if this.search.activeGlobalSearchTerm}}
        <AssistantItem
          @suffix={{i18n "discourse_ai.embeddings.quick_search.suffix"}}
          @icon="discourse-sparkles"
          @closeSearchMenu={{@closeSearchMenu}}
          @searchTermChanged={{this.searchTermChanged}}
          @suggestionKeyword={{@suggestionKeyword}}
        />
      {{/if}}
    
  */
  {
    "id": "xqHDbd5x",
    "block": "[[[1,\"\\n    \"],[18,3,null],[1,\"\\n\\n\"],[41,[30,0,[\"search\",\"activeGlobalSearchTerm\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@suffix\",\"@icon\",\"@closeSearchMenu\",\"@searchTermChanged\",\"@suggestionKeyword\"],[[28,[32,1],[\"discourse_ai.embeddings.quick_search.suffix\"],null],\"discourse-sparkles\",[30,1],[30,0,[\"searchTermChanged\"]],[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@closeSearchMenu\",\"@suggestionKeyword\",\"&default\"],false,[\"yield\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/search-menu-initial-options/ai-quick-semantic-search.js",
    "scope": () => [_assistantItem.default, _i18n.default],
    "isStrictMode": true
  }), _AiQuickSemanticSearch), _AiQuickSemanticSearch), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "search", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "quickSearch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "searchTermChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "searchTermChanged"), _class.prototype)), _class);
});
define("discourse/plugins/discourse-ai/discourse/connectors/fast-edit-footer-after/ai-edit-suggestion-button", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/plugins/discourse-ai/discourse/lib/show-ai-helper", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _object, _service, _dButton, _ajax, _ajaxError, _showAiHelper, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _AiEditSuggestionButton;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AiEditSuggestionButton = _exports.default = (_class = (_AiEditSuggestionButton = class AiEditSuggestionButton extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "loading", _descriptor2, this);
      _initializerDefineProperty(this, "suggestion", _descriptor3, this);
      _initializerDefineProperty(this, "_activeAIRequest", _descriptor4, this);
    }
    static shouldRender(outletArgs1, helper1) {
      return (0, _showAiHelper.showPostAIHelper)(outletArgs1, helper1);
    }
    get disabled() {
      return this.loading || this.suggestion?.length > 0;
    }
    get mode() {
      return this.currentUser?.ai_helper_prompts.find(prompt1 => prompt1.name === "proofread");
    }
    suggest() {
      this.loading = true;
      this._activeAIRequest = (0, _ajax.ajax)("/discourse-ai/ai-helper/suggest", {
        method: "POST",
        data: {
          mode: this.mode.id,
          text: this.args.outletArgs.initialValue,
          custom_prompt: ""
        }
      });
      this._activeAIRequest.then(_ref => {
        let {
          suggestions: suggestions1
        } = _ref;
        this.suggestion = suggestions1[0].trim();
        this.args.outletArgs.updateValue(this.suggestion);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.loading = false;
      });
      return this._activeAIRequest;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#unless @outletArgs.newValue}}
        <DButton
          class="btn-small btn-ai-suggest-edit"
          @action={{this.suggest}}
          @icon="discourse-sparkles"
          @label="discourse_ai.ai_helper.fast_edit.suggest_button"
          @isLoading={{this.loading}}
          @disabled={{this.disabled}}
        />
      {{/unless}}
    
  */
  {
    "id": "9jHc25oQ",
    "block": "[[[1,\"\\n\"],[41,[51,[30,1,[\"newValue\"]]],[[[1,\"      \"],[8,[32,0],[[24,0,\"btn-small btn-ai-suggest-edit\"]],[[\"@action\",\"@icon\",\"@label\",\"@isLoading\",\"@disabled\"],[[30,0,[\"suggest\"]],\"discourse-sparkles\",\"discourse_ai.ai_helper.fast_edit.suggest_button\",[30,0,[\"loading\"]],[30,0,[\"disabled\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/fast-edit-footer-after/ai-edit-suggestion-button.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), _AiEditSuggestionButton), _AiEditSuggestionButton), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "suggestion", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_activeAIRequest", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "suggest", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "suggest"), _class.prototype)), _class);
});